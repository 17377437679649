<template>
    <div id="LatestJudgement" class="m-5 pb-5 table-data">
        <section>


            <div class="columns is-justify-content-center search-section">
                <div class="column is-6">
                  <b-input class="input-brder-search" placeholder="Enter any 4 Char in Word" icon-right="magnify" rounded></b-input>
                 </div>
            </div>


        </section>
    </div>
</template>
<script>
    export default {
        name: 'Word',


    }
</script>